/* Default styles for larger screens (not mobile) */
.mobile-footer {
    display: none;
  }
  
  /* Media query for mobile devices with a maximum width of 767px */
  @media (max-width: 767px) {
    .mobile-footer {
        z-index: 9999;
      position: fixed;
      bottom: 0;
      width: 100%;
      display: flex;
      background-color: #fff; /* Adjust background color as needed */
      box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
    }
  
    .enquire-button,
    .call-us-button {
      flex: 1;
      background-color: transparent;
      color: black; /* Adjust text color as needed */
      border: none;
      padding: 10px;
      cursor: pointer;
    }
  
    .enquire-button {
      background-color: #fc4c4c;
      color: white;
    }
  
    .call-us-button {
      background-color: #303443;
      color: white;
    }
  }
  