
.wpo-site-footer {
    background: #303443;
    font-size: 15px;
    overflow: hidden;
    position: relative
}

.wpo-site-footer ul {
    list-style: none
}

.wpo-site-footer li,.wpo-site-footer p {
    color: #fff
}

.wpo-site-footer .container {
    position: relative
}

.wpo-site-footer .wpo-upper-footer {
    padding: 90px 0
}

@media(max-width: 991px) {
    .wpo-site-footer .wpo-upper-footer {
        padding:60px 0 0
    }
}

@media(max-width: 767px) {
    .wpo-site-footer .wpo-upper-footer {
        padding:60px 0 0
    }
}

@media(max-width: 991px) {
    .wpo-site-footer .wpo-upper-footer .col {
        margin-bottom:70px;
        min-height: 235px
    }
}

@media(max-width: 767px) {
    .wpo-site-footer .wpo-upper-footer .col {
        margin-bottom:60px;
        min-height: auto
    }
}

.wpo-site-footer .widget-title {
    margin-bottom: 30px
}

@media(max-width: 767px) {
    .wpo-site-footer .widget-title {
        margin-bottom:20px
    }
}

.wpo-site-footer .widget-title h3 {
    color: #fff;
    font-family: Futura PT Demi;
    font-size: 20px;
    margin: 0;
    position: relative;
    text-transform: uppercase
}

@media(max-width: 991px) {
    .wpo-site-footer .widget-title h3 {
        font-size:20px;
        font-size: 1.25rem
    }
}

.wpo-site-footer .about-widget .logo {
    max-width: 180px
}

.wpo-site-footer .about-widget p {
    line-height: 1.9em;
    margin-bottom: .8em
}

.wpo-site-footer .about-widget p:last-child {
    margin-bottom: 0
}

.wpo-site-footer .about-widget ul {
    overflow: hidden;
    padding-top: 10px
}

.wpo-site-footer .about-widget ul li {
    float: left;
    font-size: 22px
}

.wpo-site-footer .about-widget ul li a {
    background: hsla(0,0%,100%,.1);
    border-radius: 50%;
    color: #fff;
    display: block;
    font-size: 18px;
    height: 36px;
    line-height: 40px;
    text-align: center;
    width: 36px
}

.wpo-site-footer .about-widget ul li a:hover {
    background: #fff;
    color: #303443
}

.wpo-site-footer .about-widget ul li+li {
    margin-left: 25px
}

@media screen and (min-width: 1200px) {
    .wpo-site-footer .wpo-service-link-widget {
        padding-left:70px
    }
}

.wpo-site-footer .link-widget {
    overflow: hidden
}

@media screen and (min-width: 1200px) {
    .wpo-site-footer .link-widget {
        padding-left:75px
    }

    .wpo-site-footer .link-widget.s1 {
        padding-left: 95px
    }
}

@media(max-width: 1199px) {
    .wpo-site-footer .link-widget {
        padding-left:20px
    }
}

@media(max-width: 991px) {
    .wpo-site-footer .link-widget {
        padding-left:0
    }
}

@media(max-width: 767px) {
    .wpo-site-footer .link-widget {
        max-width:350px
    }
}

.wpo-site-footer .link-widget ul li {
    position: relative
}

.wpo-site-footer .link-widget ul li a {
    color: #fff;
    font-size: 17px
}

.wpo-site-footer .link-widget ul li a:hover {
    text-decoration: underline
}

.wpo-site-footer .link-widget ul li+li {
    padding-top: 15px
}

.wpo-site-footer .contact-ft {
    margin-top: 20px
}

.wpo-site-footer .contact-ft ul li {
    color: #e5e3e3;
    font-size: 17px;
    padding-bottom: 15px;
    padding-left: 35px;
    position: relative
}

.wpo-site-footer .contact-ft ul li i {
    left: 0;
    position: absolute;
    top: 0
}

.wpo-site-footer .contact-ft ul li .fi:before {
    font-size: 20px;
    margin-right: 15px
}

.wpo-site-footer .wpo-lower-footer {
    background: #1e2332;
    position: relative;
    text-align: center
}

.wpo-site-footer .wpo-lower-footer .row {
    padding: 20px 0;
    position: relative
}

.wpo-site-footer .wpo-lower-footer .copyright {
    display: inline-block;
    font-size: 15px;
    font-size: .9375rem;
    margin: 0
}

.wpo-site-footer .wpo-lower-footer .copyright a {
    color: #fff;
    text-decoration: none
}

@media(max-width: 991px) {
    .wpo-site-footer .wpo-lower-footer .copyright {
        display:block;
        float: none
    }
}
